import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const Router = () => (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )

export default Router;